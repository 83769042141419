import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import hazInlineImg from 'assets/img/navbar/Haz/Series-8600-8700-MP-Inline.png';
import hazInsertionImg from 'assets/img/navbar/Haz/Series-8800-MP-Insertion.png';

import hazRInlineImg from 'assets/img/navbar/Haz/Series-8000-8100-MP-Inline-Remote.png';
import hazRInsertionImg from 'assets/img/navbar/Haz/Series-8200-MP-Insertion-Remote.png';

import hazFatInlineImg from 'assets/img/navbar/Haz/Series-9700-MP-Inline-FAT.png';
import hazFatInsertionImg from 'assets/img/navbar/Haz/Series-9800-MP-Insertion-FAT.png';

import hazFatRInlineImg from 'assets/img/navbar/Haz/Series-9100-MP-Inline-Remote-FAT.png';
import hazFatRInsertionImg from 'assets/img/navbar/Haz/Series-9200-MP-Insertion-Remote-FAT.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Haz({mobileNavbarState}){
  const product_data = [
    {
      title: 'HAZ',
      link: 'haz',
      description: 'Certified for Hazardous Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazInlineImg,
      default_image: hazInlineImg,
      left_image: hazInlineImg,
      right_image: hazInsertionImg
    },
    {
      title: 'HAZ R',
      link: 'haz-r',
      description: 'Transmitter Certified for Hazardous Locations with Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazRInlineImg,
      default_image: hazRInlineImg,
      left_image: hazRInlineImg,
      right_image: hazRInsertionImg
    },
    {
      title: 'HAZ FAT',
      link: 'haz-fat',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Certified for Hazardous Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazFatInlineImg,
      default_image: hazFatInlineImg,
      left_image: hazFatInlineImg,
      right_image: hazFatInsertionImg
    },
    {
      title: 'HAZ FAT R',
      link: 'haz-fat-r',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Hazardous Locations and Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazFatRInlineImg,
      default_image: hazFatRInlineImg,
      left_image: hazFatRInlineImg,
      right_image: hazFatRInsertionImg
    }
  ]

  const link_dir_inline = '/products/haz/inline/';
  const link_dir_insertion = '/products/haz/insertion/';

  return(
    <>
      <div id="haz" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>
                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_inline + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Inline
                      </Col>

                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default Haz;
